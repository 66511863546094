<mat-dialog-title fxLayout="row" fxLayoutAlign="space-between">
  <h2>{{ translateService.get(translations.appFilterDialog.title) | async | translate }}</h2>
  <button
    class="clear-btn"
    mat-icon-button
    aria-label="Clear"
    (click)="close()">
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-title>

<mat-dialog-content class="mat-typography">
  <app-filters-bar-container [isDesktopView]="isDesktopView" ></app-filters-bar-container>
</mat-dialog-content>

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { filter, takeUntil } from 'rxjs/operators';
import { PendoService, ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { GTCxMobileHelperService } from '@zonar-ui/gtcx-mobile-helper';
import { MobileLayoutService } from '@app/services/mobile-layout.service';
import { DataDogService } from '@app/services/data-dog.service';
import { PlatformFacade } from '@app/modules/platform/facade/platform.facade';
import { AuthService } from '@auth0/auth0-angular';
import { ServerOfflineService } from '@app/services/server-offline.service';
import { environment } from '@environments/environment';
import { PermissionsService } from '@zonar-ui/auth';
import { LoadingAnimationService } from '@app/services/loading-animation.service';
import { Subject } from 'rxjs';
import { FeatureToggleService } from '@app/modules/feature-toggles/services/feature-toggle.service';
import { SidenavConfigService } from '@app/services/sidenav-config.service';
import { CurrentCompanyService } from './services/current-company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private auth: AuthService,
    private datadog: DataDogService,
    private mobileLayoutService: MobileLayoutService,
    private serverOfflineService: ServerOfflineService,
    private overlayContainer: OverlayContainer,
    private pendoService: PendoService,
    private permissionsService: PermissionsService,
    private platformFacade: PlatformFacade,
    private router: Router,
    private zuiAnalytics: ZonarUiAnalyticsService,
    public loadingAnimationService: LoadingAnimationService,
    private featureToggleService: FeatureToggleService,
    private sidenavConfig: SidenavConfigService,
    private currentCompanyService: CurrentCompanyService,
    private gtcxMobileHelperService: GTCxMobileHelperService
  ) {}

  isMobile$ = this.platformFacade.getIsMobile();

  currentCompanyId: string;

  region = environment.region;
  hamburgerMenuEnabled: boolean = environment.hamburgerMenuEnabled;

  sidenavParams = this.sidenavConfig.getSidenavParams(this.region);
  sidenavHeader = this.sidenavConfig.getSidenavHeaderConfig();
  sidenavMenu = null; // ZTT-3116 if we can rip out EU menu, this can be deleted
  sidenavFooter = null;
  onDestroy$ = new Subject<void>();
  pageTitleEl: HTMLElement;
  hideMobileMenuButton = false;
  numberTimesCompanySelected = 0;

  // TODO: Do we still want this after VDO app is separated?
  logoutIfConditionsMet = (event, auth) => {
    // when we hide the sidenav logout button, provide a /logout route so that users can still logout
    if (event instanceof NavigationStart && this.sidenavParams.hideLogoutButton && event.url.startsWith('/logout')) {
      auth.logout();
    }
  };

  ngOnInit() {
    // Solves bug in https://zonarsystems.atlassian.net/browse/ZTT-3058
    // we need to do this on initial page load so the interceptor in the zonar-ui-auth package sends the correct user <id> header that matches the current company, otherwise, it sends the first company in the list of user's authorized companies, which is not necessarily the same thing on initial page load
    this.currentCompanyService
      .getCurrentCompanyId()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(companyId => {
        this.permissionsService.setCurrentCompanyContextById(companyId);
      });
    // Set page title
    this.pageTitleEl = document.getElementById('maps-page-title');
    if (this.pageTitleEl) {
      this.pageTitleEl.innerText = 'Zonar GTC';
    }

    this.serverOfflineService.showNotificationForOfflineStatus();
    this.serverOfflineService.refreshAppForOnlineStatus();

    if (!environment.devTools) {
      this.auth.isAuthenticated$.pipe(filter(isAuthenticated => Boolean(isAuthenticated))).subscribe(() => {
        // Start DataDog session replay recording
        this.datadog.startSessionReplayRecording();
      });
    }

    if (this.region === 'NA') {
      this.zuiAnalytics.addGtmToDom();
    }
    this.mobileLayoutService.compensate();
    this.mobileLayoutService.addCompensationListener();
    this.datadog.newRumTiming('app_init');

    // when we initialize pendo, some of the api calls require a zonar owner id, which can only be attached to the
    // request after the permissions are loaded. therefore, we must wait to initialize pendo until after the
    // permissions are loaded. ultimately, this logic should be handled in the pendo service itself as described here:
    // https://jira.zonarsystems.net/browse/ZTT-1015
    this.permissionsService
      .getIsPermissionsLoaded()
      .pipe(filter(permissions => Boolean(permissions)))
      .subscribe(() => {
        this.pendoService.initialize();
      });

    this.router.events.subscribe(event => {
      this.logoutIfConditionsMet.call(this, event, this.auth);

      if (event instanceof NavigationEnd && this.region === 'NA') {
        const tag = { event: 'page', pageName: event.url };
        this.zuiAnalytics.pushTag(tag);
      }
    });

    this.isMobile$.pipe(filter(isMobile => Boolean(isMobile))).subscribe(() => {
      this.overlayContainer.getContainerElement().classList.add('app-container-mobile');
    });
  }

  ngOnDestroy() {
    // Stop DataDog session replay recording
    this.datadog.stopSessionReplayRecording();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setCurrentCompanyByFormSelection(company: { title: string; value: string }) {
    // this if stops unneeded asset refresh if company has not changed on new emit (ie from sidenav being re-expanded)
    if (company?.value && company.value != this.currentCompanyId) {
      // we wait until zui-sidenav returns the selected company and calls this method as a callback
      // to query location api for assets so send custom timing to DataDog for perf analysis
      if (this.numberTimesCompanySelected == 0) {
        this.datadog.newRumTiming('sidenav_company_selected');
      }
      this.numberTimesCompanySelected++;
      this.currentCompanyId = company?.value;
      // give companyId to devcycle for feature flags
      this.featureToggleService.initializeDevCycle(this.currentCompanyId);
      // give companyId to currentCompanyService
      this.currentCompanyService.setCurrentCompanyId(this.currentCompanyId);
      // after switching companies, show the loading spinner for the first load of assets
      this.loadingAnimationService.shouldShowAssetsLoadingAnimations = true;
      this.gtcxMobileHelperService.setCurrentCompany(this.currentCompanyId);
    }
  }

  onSideNavMobileMenuButtonToggled(event) {
    this.sidenavParams.mobileOpened = event;
  }
}

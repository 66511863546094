import {
  AssetFilterSelectionModel,
  ASSET_FILTER_KEY_NAME,
  ZONE_FILTER_KEY_NAME,
  SEARCH_BAR_KEY_NAME,
  ASSET_PROPERTIES_FILTER_KEY_NAME,
  AssetPropertiesQueryParam
} from '@zonar-ui/filter';

export const POWER_STATUS_FILTER_NAME = 'power_status';
export interface FiltersBarSelections {
  [ASSET_FILTER_KEY_NAME]?: AssetFilterSelectionModel;
  [ZONE_FILTER_KEY_NAME]?: {
    id: string;
    name: string;
  };
  [SEARCH_BAR_KEY_NAME]?: {
    assetId?: string;
    driverProfileId?: string;
    driverFirstName?: string;
    driverLastName?: string;
    value?: string;
  };
  [POWER_STATUS_FILTER_NAME]?: {
    selected: string[]; // [true, false] if both selected, but in this case isAllSelected should also be true
    isAllSelected: boolean;
  };
  [ASSET_PROPERTIES_FILTER_KEY_NAME]?: AssetPropertiesFilterModel;
}

export type SortOrder = 'asc' | 'desc';
export enum SortAttribute {
  ASSET_NAME = 'assetName',
  DRIVER_NAME = 'driverName',
  UPDATES = 'geoEventTs'
}

export interface FilterLatLng {
  0: number; // latitude
  1: number; // longitude
}

export enum PowerStatus {
  ON = 'on',
  OFF = 'off'
}

export interface FiltersState {
  powerOn: PowerStatus;
  companyId: string;
  assetIds: string[];
  divisionIds: string[];
  searchTerms: string[];
  zoneIds: string[];
  geojson: string;
  sortOrder: SortOrder;
  sortAttribute: SortAttribute;
  northEast: FilterLatLng;
  southWest: FilterLatLng;
  assetProperties: AssetProperty[];
}

export const initialFiltersState: FiltersState = {
  powerOn: null,
  companyId: null,
  assetIds: [],
  divisionIds: [],
  searchTerms: [],
  zoneIds: [],
  geojson: null,
  sortOrder: null,
  sortAttribute: null,
  northEast: null,
  southWest: null,
  assetProperties: null
};

export const mockFiltersState = { ...initialFiltersState, companyId: 'foo' };

export interface FiltersParams extends AssetPropertiesFilterModel {
  asset_id?: Array<string>;
  company_id?: string;
  division_id?: Array<string>;
  power_status?: String;
  zone_id?: Array<string>;
  recentPathStartTime?: String; // we do not set from filters bar, but need to include in interface for when we set it to null returning from history view
}

export interface AssetCustomProperty {
  name: string;
  values: Array<string>;
}
export interface AssetPropertiesFilterModel {
  [AssetPropertiesQueryParam.VEHICLE_TYPE]?: Array<string>;
  [AssetPropertiesQueryParam.YEAR]?: Array<string>;
  [AssetPropertiesQueryParam.MAKE]?: Array<string>;
  [AssetPropertiesQueryParam.MODEL]?: Array<string>;
  [AssetPropertiesQueryParam.BODY_CLASS]?: Array<string>;
  [AssetPropertiesQueryParam.ENGINE_CONFIG]?: Array<string>;
  [AssetPropertiesQueryParam.GVWR]?: Array<string>;
  [AssetPropertiesQueryParam.FUEL_TYPE]?: Array<string>;
  [AssetPropertiesQueryParam.CUSTOM_PROPERTIES]?: Array<AssetCustomProperty>;
}

export interface AssetProperty {
  types?: Array<string>;
  subtypes?: Array<string>;
  engineConfigs?: Array<string>;
  grossVehicleWeightRatings?: Array<string>;
  fuelTypes?: Array<string>;
  makes?: Array<string>;
  models?: Array<string>;
  modelYears?: Array<string>;
  customProperties?: Array<AssetCustomProperty>;
}

export const initialAssetPropertyParams = {
  [AssetPropertiesQueryParam.VEHICLE_TYPE]: null,
  [AssetPropertiesQueryParam.YEAR]: null,
  [AssetPropertiesQueryParam.MAKE]: null,
  [AssetPropertiesQueryParam.MODEL]: null,
  [AssetPropertiesQueryParam.BODY_CLASS]: null,
  [AssetPropertiesQueryParam.ENGINE_CONFIG]: null,
  [AssetPropertiesQueryParam.GVWR]: null,
  [AssetPropertiesQueryParam.FUEL_TYPE]: null,
  [AssetPropertiesQueryParam.CUSTOM_PROPERTIES]: null
};

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ViewContext } from '@app/store/layout/reducers/layout.reducer';
import { Orientation } from '@app/modules/platform/store/reducers/platform.reducer';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { LoadingAnimationService } from '@app/services/loading-animation.service';
import { Translations } from '@app/core/services/i18n/translations.service';
import { TranslateService } from '@zonar-ui/i18n';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';
import { SharedMapService } from '@app/services/shared-map.service';

@Component({
  selector: 'app-mobile-location-container',
  templateUrl: './mobile-location-container.component.html',
  styleUrls: ['./mobile-location-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MobileLocationContainerComponent implements OnInit, OnDestroy {
  @Output() handleOpenFilterDialog: EventEmitter<any> = new EventEmitter<any>();

  onDestroy$ = new Subject<void>();
  isAssetSelected = false;
  currentView = ViewContext.MOBILE_MAP;
  views = ViewContext;
  showRotationMessage: boolean;
  assetsLoadState = this.locationFacade.getAssetsLoadState();
  recentPathLoadState = this.locationFacade.getRecentPathLoadState();
  viewSubContext = this.locationFacade.getSelectedViewSubContext();
  translated;
  text;
  contextSelectorText = {
    [ViewContext.LIST]: '',
    [ViewContext.MOBILE_MAP]: ''
  };
  loading = true;
  constructor(
    public locationFacade: LocationFacade,
    private changeDetectorRef: ChangeDetectorRef,
    public loadingAnimationService: LoadingAnimationService,
    public translations: Translations,
    public translateService: TranslateService,
    public leafletZoneService: LeafletZoneService,
    public sharedMapService: SharedMapService
  ) {
    this.translations.translationsLoadState
      .pipe(filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL))
      .subscribe(() => {
        this.translated = this.translateService.instant([
          this.translations.selectortextmobile.viewMap,
          this.translations.selectortextmobile.viewList
        ]);
        this.contextSelectorText = {
          [ViewContext.LIST]: this.translated[this.translations.selectortextmobile.viewMap],
          [ViewContext.MOBILE_MAP]: this.translated[this.translations.selectortextmobile.viewList]
        };
        this.text = this.contextSelectorText[this.currentView];
      });
  }

  hideMobileSidenavHeader: HTMLElement;
  ngOnInit() {
    // need to clear selectd asset again in case user clicks back to list view via browser back button
    this.locationFacade.clearSelectedAsset();
    this.locationFacade.setViewContext(ViewContext.MOBILE_MAP);

    this.locationFacade
      .getOrientation()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(orientation => {
        this.showRotationMessage = orientation === Orientation.LANDSCAPE;
        this.changeDetectorRef.detectChanges();
      });

    // show/hide sidenav-header based on VDO native app CNAME
    if (window.location.hostname.includes('vdo-native-app')) {
      this.hideMobileSidenavHeader = document.getElementById('sidenav-header');
      if (this.hideMobileSidenavHeader) {
        this.hideMobileSidenavHeader.style.display = 'block';
      }
    }

    combineLatest([this.sharedMapService.mapAssetsLoadingState$, this.leafletZoneService.zonesLoadingState$])
      .pipe(
        tap(([mapAssetsLoadState, zonesLoadState]) => {
          this.loading =
            (this.loadingAnimationService.shouldShowAssetsLoadingAnimations &&
              mapAssetsLoadState === ResourceLoadState.LOADING) ||
            zonesLoadState === ResourceLoadState.LOADING;
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setView() {
    if (this.currentView === this.views.LIST) {
      this.currentView = this.views.MOBILE_MAP;
      // if we are in list view, we set the map pane to height 0 so that it can be resized properly to have
      // copyright logo in bottom right corner
      return;
    }
    this.currentView = this.views.LIST;
  }

  setText() {
    if (this.text === this.contextSelectorText[ViewContext.MOBILE_MAP]) {
      this.text = this.contextSelectorText[ViewContext.LIST];
      return;
    }
    this.text = this.contextSelectorText[ViewContext.MOBILE_MAP];
  }

  toggleMobileViewContext() {
    this.setView();
    this.setText();
  }

  handleAssetListUpdate() {
    this.changeDetectorRef.detectChanges();
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDialogComponent {
  destroyed$ = new Subject();
  isDesktopView: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    public translations: Translations,
    public translateService: TranslateService
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}

// ZTT-3664 TODO: this component is now only used for mobile because button which emits is otherwise hidden.

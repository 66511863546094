<div class="asset-list-container" fxLayout="column" fxFill>
  <ng-container *ngIf="loadComplete() | async; else loadingSkeletons">
    <div class="asset-list-title" fxLayout="row" fxLayoutAlign="start baseline">
      <h3 data-e2e-asset-list>{{listTitle}}</h3>
      <span *ngIf="assetCount > 0" class="count">{{assetCountResults | async}}</span>
      <app-asset-list-info></app-asset-list-info>
    </div>
    <div class="sorting-select" *ngIf="assetCount > 0" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="0.3rem">
      <mat-label class="sorting-select-label">{{ translateService.get(translations.appAssetList.sortingSelectLabel) | async | translate }}</mat-label>
      <mat-form-field appearance="none">
        <mat-select [formControl]="sortingControl" class="sorting-select-control">
          <mat-option *ngFor="let option of sortingOptions" [value]="option" class="sorting-select-option">
            {{option.viewValue}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      style="overflow-anchor: none"
      class="asset-list-scroll-container"
      infiniteScroll
      [infiniteScrollDisabled]="listUpdating"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="150"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
      <app-asset-list-item 
        *ngFor="let asset of assets; trackBy:trackFn;" 
        [asset]="asset"
        (hoverOver)="hoverOver.emit(asset)"
        (hoverOut)="hoverOut.emit()" 
        [routerLink]="['/assets', asset.assetId, 'live']"
      >
      </app-asset-list-item>
      <p *ngIf="assetsLoaded && assets.length === 0" class="no-results-text">{{ translateService.get(translations.appAssetList.noResultsMsg) | async | translate }}</p>
    </div>
  </ng-container>
  <ng-template #loadingSkeletons>
    <div class='asset-list-scroll-container'>
      <div *ngFor='let i of [].constructor(skeletonMaxCount);'>
        <app-asset-list-item-skeleton></app-asset-list-item-skeleton>
      </div>
    </div>
  </ng-template>
</div>
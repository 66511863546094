import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FiltersService } from '@app/services/filters.service';
import { SEARCH_BAR_KEY_NAME, SearchFiltersControlModel } from '@zonar-ui/filter';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-asset-driver-search',
  templateUrl: './asset-driver-search.component.html',
  styleUrls: ['./asset-driver-search.component.scss']
})
export class AssetDriverSearchComponent implements OnInit, OnDestroy {
  @Output() filterClick: EventEmitter<any> = new EventEmitter<any>();
  filterOption: SearchFiltersControlModel;
  companyId: string;

  onDestroy$ = new Subject<void>();

  constructor(private filtersService: FiltersService) {
    this.filterOption = this.filtersService.getSearchFilterOption();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.filtersService
      .getFiltersState()
      .pipe(
        filter(filters => filters.companyId != null),
        distinctUntilChanged((prev, curr) => prev.companyId === curr.companyId),
        takeUntil(this.onDestroy$)
      )
      .subscribe(filterState => {
        this.companyId = filterState.companyId;
      });
  }

  handleSearchBarSelectionChange(selection) {
    const data = {};
    data[SEARCH_BAR_KEY_NAME] = selection;
    this.filtersService.setStateFromFiltersBar(data);
  }
}

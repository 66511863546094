import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
// import { AssetIoService } from '@app/modules/asset-io/asset-io.service';
import { FeatureToggleService } from '@app/modules/feature-toggles/services/feature-toggle.service';
import { UiUtilities } from '@app/services/ui-utilities';

@Component({
  selector: 'app-asset-list-item',
  templateUrl: './asset-list-item.component.html',
  styleUrls: ['./asset-list-item.component.scss']
})
export class AssetListItemComponent {
  @Input() asset: ViewableAsset;

  @Output() hoverOver: EventEmitter<ViewableAsset> = new EventEmitter<ViewableAsset>();
  @Output() hoverOut: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemClick: EventEmitter<ViewableAsset> = new EventEmitter<ViewableAsset>();

  constructor(public featureToggleService: FeatureToggleService, public uiUtils: UiUtilities) {}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

// a service to hold the current company id for reference throughout the app in a singleton
export class CurrentCompanyService {
  constructor() {}

  private currentCompanyId$: BehaviorSubject<string> = new BehaviorSubject(null);

  getCurrentCompanyId() {
    return this.currentCompanyId$;
  }

  // currently we set after sidenav bootstrap, and from asset by id response when going by link directly to details/history view
  setCurrentCompanyId(companyId: string) {
    this.currentCompanyId$.next(companyId);
  }
}

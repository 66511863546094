<mat-card data-e2e-asset-list-item data-cy="asset-list-item" class="asset-list-item-card" (mouseenter)="hoverOver.emit(asset)" (mouseleave)="hoverOut.emit()"
    (click)="itemClick.emit(asset)">

    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1rem">
        <!-- column 1 -->
        <div fxFlex="10">
            <div class="map-marker">
                <img alt="Heading {{asset.className}}" ngClass.gt-sm="marker-icon-web" ngClass.lt-md="marker-icon-mobile" class="{{ asset.className }}" src="{{ asset.iconUrl }}" />
            </div>
        </div>

        <!-- column 2 -->
        <div fxFlex="75">
            <!-- Asset & driver name -->
            <div class="asset-list-item-name-driver-container">
                <div class="asset-list-heading">{{ asset.assetName }}</div>
                <div class="asset-list-subtext" *ngIf="asset.driverName">{{asset.driverName}}</div>
            </div>

            <!-- GTCx Event -->
            <div *appIfFeatureEnabled="'voltron-live-enabled'" data-cy="voltron-badge">
                <div data-cy="app-event-badge"><app-event-badge [events]="asset.openEvents"></app-event-badge></div>
            </div>

            <!-- SubTitle / Connection Issue -->
            <div [ngClass]="!asset.connectionIssue && asset.geoEventTs && asset.longitude && asset.latitude ? 'asset-list-subtext' : 'missing-gps-data'">
                {{ asset.subTitle }}
            </div>

            <!-- I/O Events -->
            <!-- <div>
                <app-asset-io-badge *ngIf="assetIoService.assetIoFeatureEnabled() && assetIoService.hasIoInputs(asset)"
                    [asset]="asset"></app-asset-io-badge>
            </div> -->
        </div>

        <!-- column 3 -->
        <div fxFlex="30">
            <!-- Sidebar Message (e.g., just now, 1 week ago, etc.)-->
            <div class="asset-list-sidebar">{{ asset.sidebarMessage }}</div>

            <!-- State of Charge -->
            <div *ngIf="asset?.stateOfCharge" class="asset-list-state-of-charge" fxLayout="row" fxLayoutGap="0.25rem">
                <div><zui-icon icon="ev_station"></zui-icon></div>
                <div class="asset-list-sidebar">{{uiUtils.getRoundedPercentText(asset.stateOfCharge)}}</div>
            </div>
        </div>
    </div>

</mat-card>

<div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10"  class="asset-driver-search-container">
    <zui-search-bar
    [label]="filterOption?.options?.label"
    [pageSize]="filterOption?.options?.pageSize"
    [autocompleteMinQueryLength]="filterOption?.options?.autocompleteMinQueryLength"
    (handleSearchBarSelectionChange)="handleSearchBarSelectionChange($event)"
    [companyId]="companyId"
    ></zui-search-bar>
    <div  class="filter-icon-container" (click)="filterClick.emit()">
        <span class="material-icons">filter_list</span>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '@app/store';
import { PlatformFacade } from '@app/modules/platform/facade/platform.facade';
import { ReverseGeocoderService } from '@app/modules/reverse-geocoder/services/reverse-geocoder.service';
import { PermissionsService } from '@zonar-ui/auth';

import {
  selectNearbyAssets,
  selectNearbyAssetsEnabled,
  selectNearbyAssetsLoadState
} from '@app/store/asset/selectors/assets.selectors';

import { loadSelectedAsset, clearNearbyAssets, clearRecentPath } from '@app/store/asset/actions/assets.actions';

import { ViewContext, DetailsSubcontext } from '@app/store/layout/reducers/layout.reducer';
import { selectViewContext } from '@app/store/layout/selectors/layout.selectors';
import { setViewContext, setViewSubContext } from '@app/store/layout/actions/layout.actions';

import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { clearDriverProfile } from '@app/store/driver/actions/driver.actions';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { LeafletService } from '@app/modules/location/services/leaflet.service';

@Injectable({
  providedIn: 'root'
})
export class AssetDetailsFacade {
  constructor(
    private store: Store<AppState>,
    private platformFacade: PlatformFacade,
    private geocode: ReverseGeocoderService,
    private leafletService: LeafletService,
    public permissionsService: PermissionsService
  ) {}

  // ZTT-3647 TODO: these store-facing nearby asset methods should be deprecated
  // https://zonarsystems.atlassian.net/browse/ZTT-3647
  clearNearbyAssets() {
    this.store.dispatch(clearNearbyAssets());
  }

  getNearbyAssets(): Observable<ViewableAsset[]> {
    return this.store.select(selectNearbyAssets);
  }

  getNearbyAssetsLoadState(): Observable<ResourceLoadState> {
    return this.store.select(selectNearbyAssetsLoadState);
  }

  getCurrentCompanyContext() {
    return this.permissionsService.getCurrentCompanyContext();
  }

  getReverseGeocodeForPoint(lat, lon) {
    return this.geocode.getReverseGeocode(lat, lon);
  }

  getOrientation() {
    return this.platformFacade.getOrientation();
  }

  getIsMobile() {
    return this.platformFacade.getIsMobile();
  }

  getSelectViewContext() {
    return this.store.select(selectViewContext);
  }

  setViewContext(context: ViewContext) {
    this.store.dispatch(setViewContext({ context }));
  }

  setViewSubContext(subContext: DetailsSubcontext) {
    this.store.dispatch(setViewSubContext({ subContext }));
  }

  clearRecentPath() {
    this.store.dispatch(clearRecentPath());
  }

  clearDriverProfile() {
    this.store.dispatch(clearDriverProfile());
  }

  refreshMap(assets: ViewableAsset[], nearbyAssets: ViewableAsset[] = [], selectedAsset?: ViewableAsset) {
    this.leafletService.refreshMap(assets, nearbyAssets, selectedAsset);
  }

  zoomToAssets(assets: ViewableAsset[]) {
    this.leafletService.zoomToAssets(assets);
  }

  setHover($event) {
    this.leafletService.setHighlightMarker($event);
  }

  clearHover() {
    this.leafletService.clearHighlightMarker();
  }
  showNearbyAssets() {
    this.leafletService.setShowNearbyAssets(true);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, first, mergeMap, Subscription, tap } from 'rxjs';
import { featureGroup, LatLngExpression, Map as LeafletMap, polygon as leafletPolygon, Polygon } from 'leaflet';
import { Category, GTCxZoneLabel, Resources, Zone } from '../zones.model';
import { CurrentCompanyService } from '@app/services/current-company.service';
import { VectorTileLayerService } from './vector-tile-layer.service';
import { DataDogService, RumTiming } from '@app/services/data-dog.service';
import { SettingsApiService } from '@app/services/settings-api.service';
import { MapSettings } from '@app/modules/location/models/settings.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { Labelgun } from '@app/modules/shared/labelgun/labelgun';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
import { reverseLongLats } from '@app/modules/shared/utilities/utilities';
import { ZonesService } from './zones.service';

declare let L;

@Injectable({
  providedIn: 'root'
})
export class LeafletZoneService {
  // noted as a default color in https://zonarsystems.atlassian.net/browse/ZTT-2956
  defaultColor = '#2C2C8B';
  entriesPerIndexTreeNode = 10; // smaller => faster search, slower load, larger => slower search, faster load.
  zoneFeatureGroup = featureGroup([]);
  addZonesVectorTilesSubscription: Subscription;
  addZonesLabelPropertiesSubscription: Subscription;
  zonesLoadingState$ = new BehaviorSubject<ResourceLoadState>(ResourceLoadState.INITIAL);
  labelLayers = new Map<string, GTCxZoneLabel>();
  map: LeafletMap;
  labelgun: Labelgun;
  selectedZoneLayer: Polygon;
  _leafletPolygon = leafletPolygon; // We are doing this for testing to make mocking easier

  leafletZonesEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private currentCompany: CurrentCompanyService,
    private datadogService: DataDogService,
    private settingsService: SettingsApiService,
    private vectorTileService: VectorTileLayerService,
    private zonesService: ZonesService
  ) {
    this.labelgun = new Labelgun(this.hideLabel, this.showLabel, this.entriesPerIndexTreeNode);
  }

  setMap(map: LeafletMap) {
    this.map = map;
  }

  getZonesEnabled() {
    return this.leafletZonesEnabled$;
  }

  showLabel(label: GTCxZoneLabel) {
    label.setOpacity(1);
  }

  hideLabel(label: GTCxZoneLabel) {
    label.setOpacity(0);
  }

  getZoneColor(zoneId: string): string {
    const zone = this.labelLayers.get(zoneId);
    return zone && zone.properties && zone.properties.color ? zone.properties.color : this.defaultColor;
  }

  addZonesToMap() {
    this.leafletZonesEnabled$.next(true);
    this.zonesLoadingState$.next(ResourceLoadState.LOADING);
    this.onMapMoveEvent(this.map, 'moveend');
    this.onMapMoveEvent(this.map, 'zoom');

    const waitForAuxiliaryData = combineLatest([
      this.currentCompany.getCurrentCompanyId().pipe(distinctUntilChanged()),
      this.zonesService.getDivisionIds()
    ]);

    this.addZonesVectorTilesSubscription = waitForAuxiliaryData
      .pipe(
        tap(_ => {
          // resets to clean slate if a multicompany/zonar user has switched companies
          this.clearZoneFeatureGroup(false);
        }),
        mergeMap(([companyId, divisionIds]) => {
          return this.vectorTileService.getVectorTileLayer$(companyId, divisionIds).pipe(
            tap(vectorTileLayer => {
              const timingArray = new Array<RumTiming>();
              vectorTileLayer.on('loading', () => {
                timingArray.push(this.datadogService.newRumTiming('zones_incremental_load', false));
              });
              vectorTileLayer.on('load', () => {
                this.datadogService.sendRumTiming(timingArray.pop());
                this.zonesLoadingState$.next(ResourceLoadState.LOAD_SUCCESSFUL);
              });

              this.zoneFeatureGroup.addLayer(vectorTileLayer);
            }),
            delay(0)
          );
        })
      )
      .subscribe(_ => this.map.addLayer(this.zoneFeatureGroup));

    this.addZonesLabelPropertiesSubscription = this.vectorTileService.zoneLabelProperties$
      .pipe(
        tap(labelProps => {
          if (this.labelLayers.has(labelProps.id)) {
            return;
          }

          const color = labelProps.color ?? this.defaultColor;
          const icon = L.divIcon({
            className: 'gtcx-zone-name',
            html: `<label style="color: ${color}" id=${labelProps.id}>${labelProps.name}</label>`
          });
          const labelLayer = L.marker(labelProps.centroid.coordinates, {
            icon: icon,
            interactive: false
          }).setZIndexOffset(-100) as GTCxZoneLabel;

          labelLayer.zoneArea = labelProps.area;
          labelLayer.name = labelProps.name;
          labelLayer.id = labelProps.id;
          labelLayer.properties = labelProps;
          labelLayer.latLong = labelProps.centroid.coordinates;
          labelLayer.setOpacity(0);

          if (!this.labelLayers.has(labelLayer.id)) {
            this.labelLayers.set(labelLayer.id, labelLayer);
            this.zoneFeatureGroup.addLayer(labelLayer);
          }
        }),
        debounceTime(100)
      )
      .subscribe(_ => {
        this.loadLabelgun();
      });
  }

  buildFilteredLabelsArray(): GTCxZoneLabel[] {
    const mapBounds = this.map.getBounds();
    return Array.from(this.labelLayers, ([id, label]) => label).filter(ll =>
      mapBounds.contains(ll.properties.centroid.coordinates as LatLngExpression)
    );
  }

  loadLabelgun() {
    if (this.map && this.labelLayers?.size) {
      this.labelgun.reset();
      const labels = this.buildFilteredLabelsArray();
      labels.forEach(label => {
        this.addZoneLabel(label as GTCxZoneLabel);
      });
      this.labelgun.update();
    }
  }

  addZoneLabel(labelLayer: GTCxZoneLabel) {
    const el = document.getElementById(labelLayer.id);
    if (!el) {
      return;
    } else {
      // We need the bounding rectangle of the label itself relative to the viewport
      const rect = el?.getBoundingClientRect();
      if (rect) {
        // We convert the container coordinates (screen space) to Lat/lng
        const bottomLeft = this.map?.containerPointToLatLng([rect.left, rect.bottom]);
        const topRight = this.map?.containerPointToLatLng([rect.right, rect.top]);
        const boundingBox = {
          bottomLeft: [bottomLeft.lng, bottomLeft.lat],
          topRight: [topRight.lng, topRight.lat]
        };

        // Ingest the label into labelgun itself
        this.labelgun.ingestLabel(
          boundingBox,
          labelLayer.id,
          labelLayer.zoneArea, // Weight (GTC uses constant 1)
          labelLayer,
          labelLayer.name,
          false
        );
      }
    }
  }

  onMapMoveEvent(map: LeafletMap, eventName: string) {
    map.on(eventName, event => {
      this.settingsService
        .getSetting(MapSettings.MAP_ZONES)
        .pipe(first())
        .subscribe(zoneSettings => {
          const zoneSettingsOn = zoneSettings.value === 'true' ? true : false;

          if (zoneSettingsOn) {
            this.loadLabelgun();
          }
        });
    });
  }

  clearZoneFeatureGroup(alsoClearSubscriptions: boolean = true) {
    this.zoneFeatureGroup.clearLayers();
    this.labelLayers.clear();
    this.labelgun.reset();
    // unsubscribe or else we'll get blasted with multiple zones calls under the hood
    if (alsoClearSubscriptions) {
      this.leafletZonesEnabled$.next(false);
      this.addZonesVectorTilesSubscription?.unsubscribe();
      this.addZonesLabelPropertiesSubscription?.unsubscribe();
    }
  }

  highlightSelectedZone(zone: Zone, map: LeafletMap) {
    const _helperAddPolygon = () => {
      const features = zone.geometry.features;

      const feature = reverseLongLats(
        features.find(feature => feature.geometry.type == 'Polygon' || feature.geometry.type == 'LineString')
      );

      const polygonCoordinates = [...feature.geometry.coordinates];
      this.selectedZoneLayer = this._leafletPolygon(
        polygonCoordinates as LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][],
        { color, fillOpacity: 0.2, fillColor: color, weight: 3 }
      ).addTo(map);

      this.zoneFeatureGroup.addLayer(this.selectedZoneLayer);
    };
    if (this.selectedZoneLayer) this.zoneFeatureGroup.removeLayer(this.selectedZoneLayer);

    let color: string;

    if (this.labelLayers?.size) {
      //getting from cached labellayers since zones already preloaded
      color = this.getZoneColor(zone.id);
      _helperAddPolygon();
    } else {
      //getting category from API when zones not yet loaded
      this.zonesService
        .getResourceById(zone.categoryId, Resources.CATEGORIES)
        .pipe(first())
        .subscribe((category: Category) => {
          color = category.color;
          _helperAddPolygon();
        });
    }
  }

  unHighlightSelectedZone() {
    if (this.selectedZoneLayer) this.zoneFeatureGroup.removeLayer(this.selectedZoneLayer);
  }
}

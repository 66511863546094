import { APP_INITIALIZER, NgModule } from '@angular/core';

import { FailedRequestInterceptor } from '@app/interceptors/failed-request.interceptor';
import { DEFAULT_TIMEOUT, TimeoutRequestInterceptor } from '@app/interceptors/timeout-request.interceptor';
import { UserInfoModule } from '@app/modules/user-info/user-info.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CoreModule } from '@app/core/core.module';
import { LocationModule } from '@app/modules/location/location.module';
import { FilterEffects } from '@app/store/filters/effects/filter.effects';
import { environment } from '@environments/environment';
import { AppComponent } from './app.component';
import { REDUCERS_TOKEN, reducersProvider } from './store';
import { AssetsEffects } from './store/asset/effects/assets.effects';
import { SearchFiltersEffects } from '@app/store/filters/effects/search-filters.effects';
import { PermissionsEffects } from '@app/store/permissions/effects/permissions.effects';
import { PlacesEffects } from '@app/store/places/effects/places.effects';
import { PlatformEffects } from '@app/modules/platform/store/effects/platform.effects';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ZonarOwnerIdInterceptor } from '@zonar-ui/auth';
import { ErrorHandlerModule } from '@app/modules/error-handler/error-handler.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DriverEffects } from '@app/store/driver/effects/driver.effects';
import { AutocompleteEffects } from '@app/store/autocomplete/effects/autocomplete.effects';

import { ZonarUICoreModule } from '@zonar-ui/core';
import { SidenavModule } from '@zonar-ui/sidenav';
import { ZonarUiFooterModule } from '@zonar-ui/footer';
import { I18nService, TranslateService, ZonarUiI18nModule } from '@zonar-ui/i18n';
import { Observable, of } from 'rxjs';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { switchMap, tap } from 'rxjs/operators';
import { Translations } from '@app/core/services/i18n/translations.service';
import { languageFallbackMap } from '@app/core/constants/language-fallback-map';

const zonarInterceptorOptions = {
  zonarOwnerIdInterceptor: {
    requiresZonarOwnerId: [
      environment.userPreferenceApiBase.url,
      environment.locationApi.url,
      environment.autocompleteApi.url,
      environment.historicalApiBase.url
    ]
  }
};

export function loadMapsTranslations(
  translateService: TranslateService,
  i18nService: I18nService,
  translations: Translations
): () => Observable<any> {
  return () => {
    return translateService
      .use(
        i18nService.getLanguageInUse(
          environment.i18n.supportedLanguages,
          environment.i18n.defaultLanguage,
          languageFallbackMap,
          true
        )
      )
      .pipe(
        tap(lang => {
          // Once the translate service has initialized, we are safe to call translations against it
          // however, if using service within a constructor or ngInit, service may not yet be initialized
          // and we should prefer .instant over .get for translations in such a case.
          translations.translationsLoadState.next(ResourceLoadState.LOAD_SUCCESSFUL);
        }),
        switchMap(lang => of(lang))
      );
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    LocationModule,
    CoreModule,
    UserInfoModule,
    ErrorHandlerModule,
    SidenavModule.forRoot({ stage: environment?.authEnv || null }),
    ZonarUICoreModule,
    ZonarUiFooterModule,
    ZonarUiI18nModule,
    StoreModule.forRoot(REDUCERS_TOKEN),
    !environment.devTools ? [] : StoreDevtoolsModule.instrument({ logOnly: !environment.devTools }),
    EffectsModule.forRoot([
      AssetsEffects,
      FilterEffects,
      PlatformEffects,
      PermissionsEffects,
      SearchFiltersEffects,
      PlacesEffects,
      DriverEffects,
      AutocompleteEffects
    ]),
    AuthModule.forRoot({
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      audience: environment.auth.audience,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      useRefreshTokens: true,
      max_age: 36000,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          `${environment.apiBase.url}/*`,
          `${environment.coreEntityApiBase.url}/*`,
          /* allowing beta2 is necessary for the settings api */
          `${environment.coreEntityApiBase.url}beta2/*`,
          `${environment.locationApi.url}/*`,
          `${environment.historicalApiBase.url}/*`,
          `${environment.userPreferenceApiBase.url}/*`,
          `${environment.autocompleteApi.url}/*`
        ]
      }
    })
  ],
  providers: [
    reducersProvider,
    { provide: 'region', useValue: environment.region },
    { provide: 'options', useValue: zonarInterceptorOptions },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 120_000_000 },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FailedRequestInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutRequestInterceptor, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    { provide: 'supportedLanguages', useValue: environment.i18n.supportedLanguages },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    { provide: 'useBrowserLanguage', useValue: true },
    {
      provide: APP_INITIALIZER,
      useFactory: loadMapsTranslations,
      deps: [TranslateService, I18nService, Translations],
      multi: true
    },
    { provide: 'Window', useValue: window },
    { provide: MAT_DATE_LOCALE, useValue: environment.i18n.defaultLanguage }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { formatTimestamp, getDateTimeText } from '@app/modules/shared/utilities/utilities';
import { GetLocaleService } from './get-locale.service';
import { EnvironmentService } from './environment.service';
import { DISTANCE_UNITS } from '@environments/shared.models';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  constructor(private getLocaleService: GetLocaleService, private envService: EnvironmentService) {}

  private browserLocale = this.getLocaleService.browserLocale;
  private l10n = 'l10n';
  private opts = {
    date: false,
    seconds: false
  };

  // @param timeStamp: string i.e. 2021-07-20T13:41:33
  // @param opts: { seconds: boolean, date: boolean } , whether to include seconds or date in the returned output, defaults to false
  getLocalizedTime(timeStamp: string, opts = this.opts) {
    let timeFormat: string = this.envService.getEnvironmentProperty(this.l10n)[this.browserLocale].timeFormat;
    let dateFormat: string = null;

    if (opts.date) {
      dateFormat = this.envService.getEnvironmentProperty(this.l10n)[this.browserLocale].dateFormat;
    }

    if (opts.seconds) {
      timeFormat = this.envService.getEnvironmentProperty(this.l10n)[this.browserLocale].timeFormatWithSeconds;
    }

    return dateFormat !== null
      ? getDateTimeText(timeStamp, `${dateFormat} ${timeFormat}`)
      : formatTimestamp(timeStamp, timeFormat);
  }

  setLocalizedNumberFormat(number: number) {
    return new Intl.NumberFormat(this.browserLocale).format(number);
  }
  setLocalizedNumberFormatWithPrecision(number: number, precision: number) {
    return new Intl.NumberFormat(this.browserLocale).format(Number.parseFloat(number.toFixed(precision)));
  }

  getUseMetricDistance() {
    return this.envService.getEnvironmentProperty(this.l10n)[this.browserLocale].distanceUnits == DISTANCE_UNITS.METRIC;
  }

  getMapTileLanguage() {
    return this.envService.getEnvironmentProperty(this.l10n)[this.browserLocale].mapTileLg;
  }
}
